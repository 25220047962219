import { config, CloudFront } from 'aws-sdk'
import { v4 as uuid } from 'uuid'
import { getCredentials } from '../auth/identity'
import { region } from '../config/aws-exports'
import { globalOptions } from '../options'
import { CdnOptions as options } from './options'

let cloudFront = undefined

export async function updateOptions(opts) {
  Object.assign(options, globalOptions, options, opts)
}

export async function initializeCDN(forceRefresh = false, opts = {}) {
  if (cloudFront === undefined || forceRefresh) {
    Object.assign(options, globalOptions, options, opts)

    config.update({
      credentials: await getCredentials(options),
      correctClockSkew: true,
      region: region,
    })

    cloudFront = new CloudFront({
      apiVersion: '2020-05-31',
      region: region,
      params: { DistributionId: options.distributionId },
      credentials: await getCredentials(options),
    })
  }
}

export async function createInvalidation(path, distributionId) {
  await initializeCDN(true, { distributionId })
  console.log('* creating invalidation for:', path)
  return cloudFront
    .createInvalidation({
      InvalidationBatch: {
        CallerReference: uuid(),
        Paths: {
          Quantity: 1,
          Items: [path],
        },
      },
    })
    .promise()
}
