const region = 'eu-central-1'
const identityPoolId = 'eu-central-1:0facab09-4f8f-4dd9-86ea-b0760a0b3f00'

const userPoolId = 'eu-central-1_5ujVLjWUG'
const poolArn =
  'arn:aws:cognito-idp:eu-central-1:396824024623:userpool/eu-central-1_5ujVLjWUG'
const userPoolWebClientId = '27pp77s8sp6bst5u5lsvpavp6n'

const bucket = 'manager-user-content'

const cloudFrontDistribution = 'E3GHQO3FRR5YZ3'

export const awsConfig = {
  region,
  identityPoolId,
  bucket,
  cloudFrontDistribution,
  auth: {
    region,
    identityPoolId,
    userPoolId,
    poolArn,
    // cookieStorage: {
    //   domain: '.aucta.io',
    //   expires: 365,
    //   sameSite: 'lax',
    //   secure: true,
    // },
    userPoolWebClientId,
  },
}

export const storage = {
  AWSS3: {
    bucket,
    region,
    identityPoolId,
  },
  level: 'private',
  customPrefix: {
    public: '',
    protected: '',
    private: '',
  },
}

export default awsConfig
