import { Alert, H4 } from '@blueprintjs/core'
import rootStore from '~/src/app/store'

const PivotWarningAlert = ({ onResetRotation }) => {
  const { appState } = rootStore
  const onConfirm = () => {
    onResetRotation()
    appState.showPivotWarningModal = false
  }
  const onCancel = () => {
    appState.showPivotWarningModal = false
  }
  return (
    <Alert
      canOutsideClickCancel
      intent="danger"
      cancelButtonText="Cancel"
      confirmButtonText="Reset rotation"
      icon="warning-sign"
      isOpen={true}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <H4>Warning</H4>
      <p>
        You cannot change the pivot point of a part that has already been
        rotated.
      </p>
      <p>
        If you wish to perform rotations using another pivot point, you will
        have to reset this part’s rotation first.
      </p>
      <p>Are you sure you want to continue?</p>
    </Alert>
  )
}

export default PivotWarningAlert
