import React, { useRef, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { action } from 'mobx'
import { useStore } from '~/src/app/store'
import { Button, Menu, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { useSelectedStep } from '~/src/hooks/training'
import { get } from 'lodash'
import CustomIcon from '~/src/app/components/Icon'

const PlayerMenu = () => {
  const { appState } = useStore()
  return (
    <div className="player-menu">
      <Menu>
        <MenuItem
          icon="fullscreen"
          text="Fullscreen"
          onClick={() => {
            appState.enableFullscreen()
          }}
        />
      </Menu>
    </div>
  )
}

/* TODO: these two could go in features/stepInstructions */

const PlayerTextInstructions = ({ step = {} }) => {
  return (
    <>
      {step.text && (
        <div className="player-text-instructions">
          <p>{step.text}</p>
        </div>
      )}
    </>
  )
}

const PlayerAudioInstructions = ({ step = {} }) => {
  const { persistence } = useStore()
  const audioFile = get(step, ['narration', 'key'], null)
  const audio = useRef()
  const [isPlaying, setPlaying] = useState(false)
  const handleStop = () => {
    if (!audio.current) return
    audio.current.pause()
  }
  const handlePlay = () => {
    if (!audio.current) return
    audio.current.currentTime = 0
    audio.current.play()
  }
  useEffect(() => {
    if (audio.current) {
      audio.current.addEventListener('play', () => setPlaying(true))
      audio.current.addEventListener('pause', () => setPlaying(false))
    }
    return () => {
      audio.current && audio.current.stop && audio.current.stop()
    }
  }, [step.narration])
  return (
    <>
      {step.narration && (
        <div className="player-audio-instructions">
          <audio
            autoPlay
            src={persistence.getContentUrl(audioFile)}
            ref={audio}
          />
          {!isPlaying && (
            <Button
              outlined
              onClick={handlePlay}
              large
              className="big-button"
              icon={<CustomIcon icon="play" />}
            />
          )}
          {isPlaying && (
            <Button
              outlined
              onClick={handleStop}
              large
              className="big-button audio-button"
              icon={<CustomIcon icon="audio" />}
            />
          )}
        </div>
      )}
    </>
  )
}

const PlayerUI = ({ hideBackButton }) => {
  const { appState, player } = useStore()
  const selectedStep = useSelectedStep()
  // handlers
  const onGoBack = action(() => appState.editorMode())
  const onNextStepButton = action(() => player.manuallyNavigateToNextStep())
  const onPrevStepButton = action(() => player.manuallyNavigateToPrevStep())
  const { idx, total } = player.getCurrentStepInfo()
  // initialize the player
  useEffect(() => {
    player.start()
  }, [])
  return (
    <div className="player-ui-container">
      <div className="top-bar">
        {!hideBackButton && (
          <div className="go-back-button-container">
            <Button
              onClick={onGoBack}
              className="go-back-button big-button"
              icon="chevron-left"
              outlined
              large
            />
          </div>
        )}

        {appState.fullscreen && (
          <div className="dropdown-menu">
            <Button
              className="dropdown-menu-button big-button"
              icon="cross"
              outlined
              large
              onClick={() => appState.disableFullscreen()}
            />
          </div>
        )}

        {/* {!appState.fullscreen && <div className="dropdown-menu"> */}
        {/*   <Button className="dropdown-menu-button big-button" icon="fullscreen" outlined large */}
        {/*     onClick={() => appState.enableFullscreen()} /> */}
        {/* </div>} */}

        {!appState.fullscreen && (
          <Popover2
            content={<PlayerMenu />}
            placement="bottom-end"
            renderTarget={({ isOpen, ref, ...targetProps }) => (
              <div className="dropdown-menu">
                <Button
                  {...targetProps}
                  elementRef={ref}
                  className="dropdown-menu-button big-button"
                  icon="more"
                  outlined
                  large
                />
              </div>
            )}
          ></Popover2>
        )}
      </div>

      <div className="player-controls">
        <div className="progress-info">
          {total > 0 && idx >= 0 ? `${idx + 1} of ${total}` : '...'}
        </div>
        <div className="button-forward">
          <Button
            onClick={onNextStepButton}
            disabled={!player.isForwardNavigationPossible()}
            className="big-button"
            large
            outlined
            icon={<CustomIcon icon="arrowRight" />}
          />
        </div>
        <div className="button-back">
          <Button
            onClick={onPrevStepButton}
            disabled={!player.isBackNavigationPossible()}
            className="big-button"
            large
            outlined
            icon={<CustomIcon icon="arrowLeft" />}
          />
        </div>
      </div>

      <div className="player-instructions-container">
        <PlayerTextInstructions step={selectedStep} />
        <PlayerAudioInstructions step={selectedStep} />
      </div>
    </div>
  )
}

export default observer(PlayerUI)
